export function getGenderIcon(gender) {
  /* 0 = 未知項 / 1 = 男性 / 2 = 女性 / 9 = 未規定項（未指明) */
  let icon = require('@/assets/icons/user-yellow-L.svg')
  switch (gender) {
    case 0:
      icon = require('@/assets/icons/user-yellow-L.svg')
      break
    case 1:
      icon = require('@/assets/icons/Male.svg')
      break
    case 2:
      icon = require('@/assets/icons/Female.svg')
      break
    case 9:
      icon = require('@/assets/icons/gender-na.svg')
      break
  }
  return icon
}

export function getMisjudgedIcon(misjudgedVal) {
  return misjudgedVal === 0 
    ? require('@/assets/icons/misjudged-disable.svg')
    : require('@/assets/icons/misjudged-yellow.svg')
}

export function getMissionIcon(missionCode) {
  const icons = {
    1: require('@/assets/icons/cofind.svg'),
    2: require('@/assets/icons/missionCheck.svg'),
    3: require('@/assets/icons/arrest.svg'),
  }
  return icons[missionCode] || require('@/assets/icons/cofind.svg')
}


export function getTagNameStr(arrTag, tagList) {
  const tags = arrTag
    .map((item) => {
      const tag = tagList.find((obj) => obj.id === item)
      return tag ? tag.name : null
    })
    .filter((tagName) => tagName !== null)
    .join(' / ')
  return tags
}

export function getTagNameList(arrTag, tagList) {
  return arrTag.map(item => {
    const objTag = tagList.find(obj => obj.id === item)
    return objTag ? objTag.name : ''
  })
}

export function goGoogleMap(latitude, longitude) {
  const url = `https://www.google.com.tw/maps/search/?api=1&query=${latitude},${longitude}`
  window.open(url, '_blank')
}

export function mergeNameId(name, id) {
  return `${name}(${id})`
}
